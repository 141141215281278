import axiosInstanceBuilder from "../config/axios/axiosInstanceBuilder";
import { useFetch } from "../config/axios/useFetch";

const baseUrl = process.env.REACT_APP_API_DEV_URL;
const endpoint = "/exam-designs/search";

const createAxiosPromise = () => {
  return axiosInstanceBuilder(baseUrl).get(endpoint);
};

export const useFindAllExamDesigns = (callAfterRender = false) => {
  const { data, isPending, error, fetchApi } = useFetch(
    createAxiosPromise,
    callAfterRender
  );

  const examDesigns = !data
    ? []
    : data
        .filter((ed) => ed.is_active)
        .map((ed) => ({ id: ed.id, name: ed.name }))
        .sort((a, b) => a.name.localeCompare(b.name));

  const findAllExamDesigns = async () => {
    const response = await fetchApi();
    const { data, ...rest } = response;
    return { examDesigns: data, ...rest };
  };

  return {
    examDesigns,
    isLoading: isPending,
    error,
    findAllExamDesigns,
  };
};
